import assetFingerprint from "@emurgo/cip14-js";
import * as helios from "@hyperionbt/helios";
import { Artwork } from "kreate-common/modules/business-types";
import { CLIENT_ENV } from "kreate-env/client";

type AssetIdParts = {
  policyId: number[]; // bytes
  tokenName: number[]; // bytes
};

export function getAssetIdParts(
  artwork: Artwork | null | undefined
): AssetIdParts | undefined {
  if (!artwork) return undefined;

  if (artwork.utxo?.datum.type === "artwork") {
    return {
      policyId: helios.hexToBytes(
        artwork.utxo.otherAssets[0].mintingPolicyHash
      ),
      tokenName: helios.textToBytes(artwork.utxo.datum.value.tokenName),
    };
  }

  if (artwork.utxo?.datum.type === "auction-english") {
    const asset = artwork.utxo.otherAssets[1];
    const token = asset?.tokens[0];
    if (!asset || !token) return undefined;
    return {
      policyId: helios.hexToBytes(asset.mintingPolicyHash),
      tokenName: helios.textToBytes(token.tokenName),
    };
  }

  if (artwork.assetId) {
    return {
      policyId: helios.hexToBytes(artwork.assetId.substring(0, 56)),
      tokenName: helios.hexToBytes(artwork.assetId.substring(56)),
    };
  }

  return undefined;
}

export function getAssetFingerprintFromParts({
  policyId,
  tokenName,
}: AssetIdParts): string {
  return assetFingerprint
    .fromParts(new Uint8Array(policyId), new Uint8Array(tokenName))
    .fingerprint();
}

export function getAssetFingerprint(
  artwork: Artwork | null | undefined
): string | undefined {
  const parts = getAssetIdParts(artwork);
  return parts ? getAssetFingerprintFromParts(parts) : undefined;
}

export function getPoolPmUrl(assetFingerprint: string) {
  const baseUrl =
    CLIENT_ENV.CARDANO_NETWORK === "Preview"
      ? "https://preview.cexplorer.io/asset/"
      : CLIENT_ENV.CARDANO_NETWORK === "Preprod"
      ? "https://preprod.cexplorer.io/asset/"
      : "https://pool.pm/";

  return baseUrl + assetFingerprint;
}
