import * as helios from "@hyperionbt/helios";
import { Artwork } from "kreate-common/modules/business-types";

import { getAssetIdParts } from "@/modules/kreate-components/components/LinkPoolPm/utils";

export function getArtworkAssetId(artwork: Artwork): string | undefined {
  const parts = getAssetIdParts(artwork);
  if (!parts) return undefined;
  return helios.bytesToHex(parts.policyId) + helios.bytesToHex(parts.tokenName);
}

export function getAuctionOverview(artwork: Artwork) {
  const datumEnglishAuction =
    artwork.utxo?.datum.type === "auction-english"
      ? artwork.utxo.datum.value
      : undefined;

  const reserve =
    datumEnglishAuction?.reserve ?? artwork.englishAuction?.reserve;
  const increment =
    datumEnglishAuction?.increment ?? artwork.englishAuction?.increment;
  const startedAt = artwork.englishAuction?.startedAt;
  const endedAt =
    datumEnglishAuction?.deadline ?? artwork.englishAuction?.endedAt;
  const numAuctionedEditions =
    artwork.utxo?.otherAssets[1]?.tokens[0]?.tokenValue ??
    artwork.englishAuction?.numAuctionedEditions;
  const assetId = getArtworkAssetId(artwork);

  if (reserve == null || increment == null) {
    return undefined;
  }

  return {
    reserve,
    increment,
    startedAt,
    endedAt,
    numAuctionedEditions,
    assetId,
  };
}
