import * as React from "react";

type Size = `${number}${"px" | "em"}`;

type Props = {
  style?: React.CSSProperties;
  className?: string;
  size?: Size;
};

export default function IconVerified({
  style,
  className,
  size = "1.2em",
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <title>
        {
          "This Artist has demonstrated their creative process and shown that they make the Artworks they post!"
        }
      </title>
      <g clipPath="url(#clip0_4272_3043)">
        <path
          d="M9.37652 0.777243C9.71991 1.00494 10.2818 1.00494 10.6247 0.777243L11.5315 0.174743C11.8747 -0.0532644 12.3359 0.045134 12.5566 0.393142L13.1399 1.31302C13.3606 1.66084 13.8736 1.88924 14.2798 1.82052L15.3534 1.63861C15.7597 1.56982 16.1415 1.84689 16.2013 2.25451L16.3605 3.33002C16.4209 3.73771 16.7963 4.15572 17.1955 4.25853L18.249 4.53025C18.6483 4.63345 18.884 5.04263 18.7731 5.43947L18.4813 6.48506C18.3705 6.88185 18.5438 7.41635 18.8667 7.67256L19.7219 8.35095C20.0447 8.60717 20.0937 9.07627 19.831 9.39396L19.1367 10.232C18.8741 10.5494 18.8154 11.1081 19.0061 11.4733L19.5103 12.4372C19.7011 12.8026 19.5557 13.2514 19.1866 13.4345L18.2112 13.9186C17.8421 14.1019 17.5616 14.5885 17.5873 14.9997L17.6546 16.0854C17.6807 16.4965 17.3646 16.8476 16.9527 16.8651L15.8655 16.9111C15.4539 16.9288 14.9996 17.2592 14.8557 17.6456L14.4775 18.6645C14.3336 19.051 13.9026 19.2432 13.5195 19.0916L12.5066 18.6912C12.1237 18.5395 11.5743 18.6562 11.2859 18.9507L10.5251 19.7277C10.2366 20.022 9.76495 20.022 9.47624 19.7281L8.71433 18.9506C8.42574 18.6563 7.87624 18.5397 7.49312 18.6913L6.48073 19.0917C6.09765 19.2434 5.66663 19.0511 5.52304 18.6645L5.14394 17.6456C5.00023 17.2594 4.54593 16.9288 4.13413 16.9112L3.04734 16.8652C2.63542 16.8476 2.31972 16.4965 2.34542 16.0855L2.41343 14.9998C2.43902 14.5887 2.15804 14.102 1.78902 13.9187L0.814016 13.4346C0.445032 13.2514 0.299133 12.8027 0.490032 12.4372L0.994251 11.4733C1.18503 11.1081 1.12636 10.5495 0.863352 10.2324L0.169016 9.39326C-0.0937963 9.07607 -0.0445775 8.60697 0.278118 8.35076L1.13351 7.67236C1.4562 7.41615 1.62972 6.88197 1.51902 6.48498L1.22652 5.43896C1.11573 5.04217 1.35152 4.63326 1.75042 4.53068L2.80472 4.25838C3.20363 4.15568 3.57952 3.73767 3.63972 3.32998L3.79882 2.25447C3.85913 1.84685 4.24073 1.56978 4.64691 1.63857L5.72062 1.82049C6.12691 1.8892 6.63984 1.6608 6.86023 1.31299L7.44323 0.393103C7.66363 0.045095 8.12515 -0.0533034 8.46863 0.174704L9.37652 0.777243Z"
          fill="#2A85FF"
          fillOpacity="0.16"
        />
        <path
          d="M16.4708 10.0107C16.4708 13.5844 13.5734 16.4814 10 16.4814C6.42685 16.4814 3.52954 13.5844 3.52954 10.0107C3.52954 6.43809 6.42673 3.54102 10 3.54102C13.5734 3.54102 16.4708 6.43809 16.4708 10.0107Z"
          fill="#2A85FF"
        />
        <path
          d="M8.9213 13.0338L6.3313 10.4437L7.19419 9.58039L8.9213 11.3072L12.8061 7.42188L13.669 8.28547L8.9213 13.0338Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4272_3043">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
