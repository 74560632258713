import * as React from "react";

type Size = `${number}${"px" | "em"}`;

type Props = {
  style?: React.CSSProperties;
  className?: string;
  size?: Size;
};

export default function IconFlagged({
  className,
  style,
  size = "1.2em",
}: Props) {
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"This user has been flagged for suspicious activity."}</title>
      <g clipPath="url(#clip0_3604_7816)">
        <g clipPath="url(#clip1_3604_7816)">
          <path
            d="M47.297 36.8434L27.6751 6.41211C25.9501 3.74023 22.0501 3.74023 20.3251 6.41211L0.703212 36.8434C-1.17179 39.7496 0.918837 43.584 4.37821 43.584H43.622C47.0813 43.5934 49.172 39.759 47.297 36.8434ZM24.0001 38.8309C22.172 38.8309 20.6813 37.3496 20.6813 35.5121C20.6813 33.6746 22.1626 32.1934 24.0001 32.1934C25.8282 32.1934 27.3188 33.6746 27.3188 35.5121C27.3188 37.3402 25.8282 38.8309 24.0001 38.8309ZM27.3188 27.4215C27.2438 29.2027 25.7813 30.609 24.0001 30.609C22.2188 30.609 20.7563 29.2027 20.6813 27.4215L20.2501 16.3777C20.1751 14.5496 21.6001 13.0027 23.4376 12.9277H23.5688H24.4407C26.2688 12.9277 27.7595 14.4184 27.7501 16.2559V16.3777L27.3188 27.4215Z"
            fill="url(#paint0_linear_3604_7816)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3604_7816"
          x1="24.0001"
          y1="-0.0664841"
          x2="24.0001"
          y2="47.4591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDAE3E" />
          <stop offset="1" stopColor="#FF0749" />
        </linearGradient>
        <clipPath id="clip0_3604_7816">
          <rect width="48" height="48" fill="white" />
        </clipPath>
        <clipPath id="clip1_3604_7816">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
