import { withDefaultProps } from "../../hoc/withDefaultProps";
import CustomButton, { CustomButton$Props } from "../CustomButton";

import styles from "./index.module.scss";

type Props = CustomButton$Props;

export type ButtonTransparent$Props = Props;

const ButtonTransparent = CustomButton.withDefaultProps({
  color: [styles.buttonColor],
  size: [styles.buttonSize],
  padding: [styles.buttonPadding],
});

/** @deprecated use ButtonTransparentV2 instead */
export default Object.assign(ButtonTransparent, {
  withDefaultProps: (
    defaultProps: Partial<Props>
  ): React.ForwardRefExoticComponent<Props> =>
    withDefaultProps(defaultProps)(ButtonTransparent),
});
