import cx from "clsx";
import { SimpleUser } from "kreate-common/modules/business-types";
import Link from "next/link";
import React from "react";

import IconSuspended from "./icons/IconSuspended";
import styles from "./index.module.scss";

import { EM_DASH } from "@/modules/common-utils/unicode";
import IconFlagged from "@/modules/kreate-components/components/UserDisplayNameViewerV2/icons/IconFlagged";
import IconVerified from "@/modules/kreate-components/components/UserDisplayNameViewerV2/icons/IconVerified";
import { getLinkToPageProfile } from "@/modules/navigation/utils/getLinkTo";

type Size = `${number}${"px" | "em"}`;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  user: SimpleUser | null | undefined;
  unstyled: true;
  openInNewTab?: boolean;
  iconSize?: Size;
  as?: "div" | "span";
  hideIcons?: boolean;
  // TODO: multiline?: boolean;
};

export default function UserDisplayNameViewerV2({
  className,
  style,
  user,
  openInNewTab = false,
  iconSize = "1em",
  as,
  hideIcons,
}: Props) {
  const Component = as;

  return (
    <div className={cx(styles.container, className)} style={style}>
      <div className={styles.displayNameContainer}>
        {Component ? (
          <Component>{user?.displayName ?? EM_DASH}</Component>
        ) : (
          <Link
            href={user ? getLinkToPageProfile(user) : "#disabled"}
            target={openInNewTab ? "_blank" : undefined}
            rel={openInNewTab ? "noreferrer" : undefined}
          >
            {user?.displayName ?? EM_DASH}
          </Link>
        )}
      </div>
      {!hideIcons ? (
        <>
          {user?.isVerified ? (
            <IconVerified className={styles.icon} size={iconSize} />
          ) : null}
          {user?.flagWarning ? (
            <IconFlagged className={styles.icon} size={iconSize} />
          ) : null}
          {user?.flagSuspended ? (
            <IconSuspended className={styles.icon} size={iconSize} />
          ) : null}
        </>
      ) : undefined}
    </div>
  );
}
