import * as React from "react";

type Size = `${number}${"px" | "em"}`;

type Props = {
  style?: React.CSSProperties;
  className?: string;
  size?: Size;
};

export default function IconSuspended({
  className,
  style,
  size = "1.2em",
}: Props) {
  return (
    <svg
      className={className}
      style={style}
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {"This user has been suspended for violating the Terms of Service."}
      </title>
      <g clipPath="url(#clip0_7914_8955)">
        <path
          d="M35 22C27.832 22 22 27.832 22 35C22 42.168 27.832 48 35 48C42.168 48 48 42.168 48 35C48 27.832 42.168 22 35 22ZM35 25C37.216 25 39.246 25.748 40.906 26.974L26.972 40.906C25.748 39.246 25 37.216 25 35C25 29.486 29.486 25 35 25ZM35 45C32.784 45 30.754 44.252 29.094 43.026L43.028 29.094C44.252 30.754 45 32.784 45 35C45 40.514 40.514 45 35 45Z"
          fill="#F44336"
        />
        <path
          d="M23.412 24H7.5C3.364 24 0 27.364 0 31.5V38.5C0 39.328 0.672 40 1.5 40H19.812C17.936 34.322 19.38 28.246 23.412 24Z"
          fill="#F44336"
        />
        <path
          d="M44.362 25.9898C44.314 25.9238 44.26 25.8598 44.202 25.7998L44.19 25.8118C38.06 31.9418 31.942 38.0598 25.814 44.1878L25.8 44.1998C25.86 44.2598 25.922 44.3118 25.99 44.3598C28.328 46.6138 31.504 47.9998 35 47.9998C42.168 47.9998 48 42.1678 48 34.9998C48 31.5058 46.614 28.3278 44.362 25.9898ZM35 44.9998C32.782 44.9998 30.732 44.2738 29.0721 43.0478L43.05 29.0718C44.276 30.7318 45 32.7818 45 34.9998C45 40.5138 40.514 44.9998 35 44.9998Z"
          fill="#D80E00"
        />
        <path
          d="M16.006 24H7.5C3.364 24 0 27.364 0 31.5V38.5C0 39.328 0.672 40 1.5 40H16.006V24Z"
          fill="#D80E00"
        />
        <circle cx="16" cy="13" r="7" fill="#F44336" />
      </g>
      <defs>
        <clipPath id="clip0_7914_8955">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
